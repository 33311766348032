@import "../main/abstracts/index";

.section.section--bg-light.section--intro-custom{
    @include media-breakpoint-down(md) {
        padding-bottom: 30vw;
    }
}
.headlineModul{
    display: flex ;
    flex-direction: column;
    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    &__left{
        width: 100%;
        @include media-breakpoint-up(md) {
            width:35%;
            padding-right: 20px;
        }
        &.full-width{
            width: 100%;
            padding-right: 0;
            h1.introCustom{
                max-width: 100%;
            }
        }
        h1.introCustom{
            max-width: 430px;
            line-height: 1.1;
            font-family: "FaveScriptPro";
            font-weight: $font__weight--normal;
            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 64px, 100px);
            @include media-breakpoint-down(sm) {
                margin-bottom: 2rem;
            }
        }
        a{
            @include fluid-type(map-get($grid-breakpoints, sm), map-get($grid-breakpoints, xxl), 18px, 22px);
        }
    }
    &__right{
        width: 100%;
        @include media-breakpoint-up(md) {
        width: 65%;
        padding-left: 20px;
        margin-top: 35px;
        }
        &-links{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0 -20px;
            margin-top: 70px;
            gap:  30px 0;
            &Item{
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-start;
                @include media-breakpoint-up(md) {
                    width: 25%;
                    min-width: 150px;
                }
            }
            a{
                text-decoration: none;
                width: 150px;
                padding: 0 ;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                &::before{
                    display: none;
                }

                p{
                    padding: 10px!important;
                }

               .circles{
                    position: relative;
                    &:before{
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 110px;
                        height: 110px;
                        border-radius: 65px;
                        background-color: rgba($color__primary, 0.2);
                        transform: translate(-50% , -50%);
                        z-index: 1;
                        @include transition;
                    }
                    &:after{
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 110px;
                        height: 110px;
                        border-radius: 75px;
                        background-color: rgba($color__primary, 0.2);
                        transform: translate(-50% , -50%);
                        z-index: 1;
                        @include transition;
                    }
                }
                &:hover,
                &.focus-within {
                    .circles:before{
                        width: 130px;
                        height: 130px;
                    }
                    .circles:after{
                        height: 148px;
                        width: 148px;
                    }
                    picture{
                        background-color: $color__primary;
                        img{
                            @include recolor($color__white);
                        }

                    }
                    p{
                        transform: translateY(20px);
                        @include transition;
                    }
                }

                &:focus-within {
                    .circles:before{
                        width: 120px;
                        height: 120px;
                    }
                    .circles:after{
                        height: 148px;
                        width: 148px;
                    }
                    picture{
                        background-color: $color__primary;
                        img{
                            @include recolor($color__white);
                        }
                    }
                    p{
                        transform: translateY(20px);
                    }

                }

                picture{
                    width: 110px;
                    height: 110px;
                    background-color: $color__white;
                    border-radius: 55px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 2;
                    @include transition;
                    img{
                        width: 32px;
                        height: 32px;
                        @include transition;
                        @include recolor($color__primary)
                    }
                }
                
                p{
                    padding: 0 10px;
                    text-align: center;
                    // font-size: 17px;
                    font-weight: 400;
                    color: $color__font;
                    @include transition;

                }
            }
        }
    }
}
body[data-template=home]{
    .headlineModul{
        &:before{
            content: "";
            width: 100%;
            padding-top: calc(100vw * 0.29);
            height: auto;
            background-image: url('/assets/website/img/sillhouette.svg');
            background-size: contain;
            position: absolute;
            background-repeat: no-repeat;
            bottom: 0;
            left: 0;
            @include media-breakpoint-up(md) {
                width: 35vw;
                padding-top: calc(35vw * 0.29);
            }
        }
    }
}
body[data-template="default"]{
    .headlineModul__right{
        margin-top: 20px;
        p a.button{
            margin-top: 20px;
        }
    }
}
